<app-layout></app-layout>
<div class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="user-graph-1">
                <div class="usergraph-head d-flex justify-content-between align-items-center">
                    <h3>Communties</h3>
                    <!-- <p class="white-bg"></p> -->
                    <select class="form-select" aria-label="Default select example"
                        (change)="selectType($event.target.value)">
                        <option value="Manager">Manager</option>
                        <option value="Employee">Employee</option>
                    </select>
                </div>
                <div>
                    <canvas id="myChart" width="800" height="300"></canvas>
                </div>
            </div>
            <!-- <div class="feedback">
                <div class="feedback-head d-flex justify-content-between align-items-center">
                    <h3>Feedback</h3>
                    <button class="btn btn-default" routerLink="/feedback/list">View All</button>
                </div>
                <div class="table-responsive-sm">
                    <table class="table noborder">
                        <thead>
                            <tr>
                                <th scope="col">Title</th>
                                <th scope="col">Message</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let feedback of feedbacks">
                                <td class="d-flex">{{feedback.title}}</td>
                                <td>{{feedback.message}}</td>
                                <td>
                                    <span routerLink="/feedback/view" [queryParams]="{id: feedback._id}"><img
                                            src="../../../../../assets/icons/view-icon.svg" alt=""></span>
                                    <span style="margin-left: 9px;" routerLink="/feedback/reply"
                                        *ngIf="!feedback.replyMsg" [queryParams]="{id: feedback._id}"><img
                                            src="../../../../../assets/icons/reply-icon.svg" alt=""></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
        </div>
        <div class="col-md-12 mt-2">
            <div class="total-counts">
                <div class="total-count total-users" routerLink="/users/list">
                    <img src="../../../assets/icons/total-users.svg" alt="">
                    <div>
                        <h2>{{dashboardData.totalUser}}</h2>
                        <p>Total Users</p>
                    </div>
                </div>
                <div class="total-count total-events" routerLink="/companies/list">
                    <img src="../../../assets/icons/total-events.svg" alt="">
                    <div>
                        <h2>{{dashboardData.totalCompany}}</h2>
                        <p>Total Companies</p>
                    </div>
                </div>
                <div class="total-count total-careers">
                    <img src="../../../assets/icons/total-careers.svg" alt="">
                    <div>
                        <h2>{{dashboardData.totalRevenue}}</h2>
                        <p>Total Revenue</p>
                    </div>
                </div>
                <div class="total-count total-careers">
                    <img src="../../../assets/icons/total-careers.svg" alt="">
                    <div>
                        <h2>{{dashboardData.AvgRevenuePerCompany}}</h2>
                        <p>Average Revenue per company</p>
                    </div>
                </div>
                <!-- <div class="total-count total-gs" routerLink="/financial/list">
                    <img src="../../../assets/icons/total-gs.svg" alt="">
                    <div>
                        <h2>{{dashboardData.gsAfasiCount}}</h2>
                        <p>Total Financial Aid</p>
                    </div>
                </div>
                <div class="total-count total-news" routerLink="/news/list">
                    <img src="../../../assets/icons/total-news.svg" alt="">
                    <div>
                        <h2>{{dashboardData.newsCount}}</h2>
                        <p>Total News</p>
                    </div>
                </div>
                <div class="total-count total-resources" routerLink="/resources/list">
                    <img src="../../../assets/icons/total-resources.svg" alt="">
                    <div>
                        <h2>{{dashboardData.resourceCount}}</h2>
                        <p>Total Resources</p>
                    </div>
                </div>
                <div class="total-count total-users" routerLink="/sectors/list">
                    <img src="../../../assets/icons/total-sectors.svg" alt="">
                    <div>
                        <h2>{{dashboardData.sectorCount}}</h2>
                        <p>Total Sectors</p>
                    </div>
                </div>
                <div class="total-count total-partners" routerLink="/partners/list">
                    <img src="../../../assets/icons/total-partners.svg" alt="">
                    <div>
                        <h2>{{dashboardData.partnerCount}}</h2>
                        <p>Total Partners</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>