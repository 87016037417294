import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    lang = sessionStorage.getItem('lang');

    header = {};

    constructor(
        private http: HttpClient
    ) { }

    // getDashboardData(data: any) {
    //     const httpOptions = {
    //         headers: new HttpHeaders({ lang: this.lang ? this.lang : 'eng', 'Content-Type': 'application / json' }), body: data
    //     };
    //     return this.http.post(this.url + 'user/admin/dashboard', httpOptions);
    // }

    getDashboardData(){
        return this.http.get(this.url + 'AdminRoute/getDashboardList');
    }

    getUserList(data:any){
        return this.http.get(this.url + 'AdminRoute/getUserList?limit='+ data.limit + '&pageNo='+ data.pageNo);
    }

    
}