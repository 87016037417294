import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitted: boolean = false;
  error = error.err;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private sharedService: SharedService
  ) { 
    
  }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  })

  ngOnInit() {
    this.getLanguage();
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid || this.loginForm.pristine) return;
    this.submitted = false;
    let user: User = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      // deviceType: "web",
      // userType: 1,
      // deviceId: 'GGJHH-KJKJK-KKKKL-KJKJ',
      // fcmToken: 'SJKSAJKSALKKJDKKJD'
    }

    // localStorage.removeItem('currentUser');
    // sessionStorage.removeItem('lang');
    this.authService.logout();

    this.authService.login(user)
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.postLanguage();
          this.router.navigateByUrl('/dashboard');
        } else {
          this.toastr.error(res.message);
        }
      },
        err => {
          this.toastr.error(err.error.message);
        }
      )
  }

  languages: any = [];
  getLanguage() {
    this.sharedService.getLanguage().subscribe((res: any) => {
      console.log('language response', res.data);
      if (res.statusCode == 200) {
        this.languages = res.data;
      }
    })
  }

  postLanguage() {
    this.sharedService.postLanguage({ languageId: (this.languages.find((el: any) => el.symbol='en'))._id })
      .subscribe((res: any) => {
        localStorage.setItem('lang', 'en');
        localStorage.setItem('language', JSON.stringify(this.languages.find((el: any) => el.symbol='en')));
        console.log('post language response', res);
      })
  }

}
