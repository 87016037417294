import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CustomFormsModule } from 'ng2-validation';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';


import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { AuthService } from './auth/services/auth.service';
import { SharedService } from './shared/services/shared.service';
import { EventService } from './views/events/services/event.service';
import { NewsService } from './views/news/services/news.service';
import { ResourceService } from './views/resources/services/resouce.service';
import { UserService } from './views/users/services/user.service';
import { SectorService } from './views/sectors/sector.service';
import { JobProfileService } from './views/job-profile/job-profile.service';
import { CareerService } from './views/careers/services/career.service';
import { EducationService } from './views/education/education.service';
import { GsService } from './views/gs/gs.service';
import { PartnerService } from './views/partners/partner.service';
import { FeedbackService } from './views/feedback/feedback.service';
import { DashboardService } from './dashboard/services/dashboard.service';
import { QuizService } from './views/quiz/services/quiz.service';
import { NotificationService } from './views/notifications/notification.service';
import { ViewsComponent } from './views/views.component';
import { AuthGuard } from './_helpers/auth.guard.service';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { SessionService } from './shared/services/session.service';
import { PolicyComponent } from './views/policy/policy.component';
import { TermsComponent } from './views/terms/terms.component';
import { FaqService } from './views/faq/services/faq.service';
// ngx-bootstrap

@NgModule({
  declarations: [AppComponent, ViewsComponent, EditProfileComponent, PolicyComponent, TermsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    ImageCropperModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ModalModule.forRoot(),
    CustomFormsModule,
    NgxSliderModule,
    NgxIntlTelInputModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuard,
    AuthService,
    SharedService,
    EventService,
    NewsService,
    ResourceService,
    UserService,
    SectorService,
    JobProfileService,
    CareerService,
    EducationService,
    GsService,
    PartnerService,
    FeedbackService,
    DashboardService,
    QuizService,
    NotificationService,
    SessionService,
    FaqService

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }


