import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { error } from 'src/app/_helpers/error';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted: boolean = false;
  error = error.err;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })

  ngOnInit(): void {
  }

  get f() {
    return this.resetForm.controls;
  }

  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    this.submitted = false;
    this.authService.forgotPassword(this.resetForm.value)
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.router.navigateByUrl('/auth/email-send');
        }else {
          this.toastr.error(res.message);
        }
      },
      err => {
        this.toastr.error(err.error.message);
      })
  }

}

